import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Toast.scss";
import { useHistory } from "react-router";
import { routes } from "core/constants";
import { notificationState } from "recoil/atoms";
import { useSetRecoilState } from "recoil";
//import read_icon from "../../../../src/assets/icons/notification-read.svg";

export const Toast = (props: any) => {
  let history: any = useHistory();
  const setNotificationConfig = useSetRecoilState(notificationState);

  const { position, children } = props;
  const [showToast, setShowToast] = useState(props.alert ? true : false);

  useEffect(() => {
    props.duration > 0 &&
      setTimeout(() => {
        setNotificationConfig({});
        setShowToast(false);
      }, props.duration); //time in ms
    props.message ? setShowToast(true) : setShowToast(false);
  }, [props, setNotificationConfig]);

  const handleClose = () => {
    setShowToast(false);
  };

  const showDetails = () => {
    let path = routes.home;
    if (
      props.data.operationType === "TARGETS_TOTAL_COUNT" ||
      props.data.operationType === "SUGGESTION_TARGET_EXPORT_DETAIL"
    ) {
      path = routes.targetFilter;
      path = path.replace(":openTab", "apply_filters");
    } else if (
      props.data.operationType === "TARGETS_SIMULATION" ||
      props.data.operationType === "TARGETS_SIMULATION_DETAILS_LOCAL"
    ) {
      path = routes.simulator;
    } else if (props.data.operationType === "TARGETS_SHIPMENT_DETAILS_LOCAL") {
      path = routes.shipmentWithFiltersId;
      path = path.replace(":id", props.data.entityId);
    } else if (
      props.data.operationType === "TARGETS_SHIPMENT_DETAILS_SFMC" ||
      props.data.operationType === "SUGGESTION_SHIPMENT_EXPORT_DETAIL"
    ) {
      path = routes.shipmentWithFiltersId;
      path = path.replace(":id", props.data.entityId);
    }else if (
      props.data.operationType === "TARGETS_IMPACT" ||
      props.data.operationType === "TARGETS_IMPACT_TOUCHPOINT"
    ) {
      path = routes.impact;
    } 

    setNotificationConfig({});
    setShowToast(false);
    if (history.location.pathname === path) {
      history.replace({ pathname: path, state: { data: props.data } });
    }
    history.push({ pathname: path, state: { data: props.data } });
  };
  const onClick = () => {
    props.onClick && props.onClick();
  };

  return (
    <>
      {showToast && (
        <Grid
          container
          alignContent="center"
          className={`toast-container ${position} ${
            props.className ? props.className : ""
          } ${props.marginColor ? props.marginColor : ""} ${
            props.contentColor ? props.contentColor : ""
          }`}
          onClick={onClick}
        >
          <Grid
            item
            xs={12}
            display="flex"
            alignContent="center"
            alignItems={"center"}
            className={`${props.alert ? "alert-content" : ""}`}
          >
            {props.alert ? (
              <Typography className="toast-box">{children}</Typography>
            ) : (
              <>
                <Box>
                  <Box
                    className={`${props.iconClass} ${
                      props.readStatus ? "icon-read" : ""
                    }`}
                  ></Box>
                </Box>
                <Grid item xs={12}>
                  <Box className="notification-content">
                    {props.headingMessage ? (
                      <Box className="text-bold">{props.headingMessage}</Box>
                    ) : (
                      ""
                    )}
                    <Box
                      className={` ${
                        props.messageClass ? props.messageClass : ""
                      } message-name`}
                    >
                      <Box className="message-first-class">
                        {props.message}
                        {/* {props.readStatus && (
                          <img src={read_icon} alt="Gray icon" />
                        )} */}
                      </Box>
                      <Grid item xs={11.7}>
                        {props.showViewButton && (
                          <Box className="message-second-class">
                            <Box
                              className={props.data.isError?"view-detail-errorclass":"view-detail-class"}
                              onClick={showDetails}
                            >
                              {props.buttonText
                                ? props.buttonText
                                : props.data.isError
                                ? props.data.errorDetail.message
                                :""}
                            </Box>
                            {/* {props.time && (
                              <Box className="time-class">{props.time}</Box>
                            )} */}
                          </Box>
                        )}
                        {(props.showDownload  ||(props.data.operationType === "TARGETS_SHIPMENT_DETAILS_LOCAL") )&& (
                          <Box className="message-second-class">
                            <Box
                              className={props.data.isError?"view-detail-errorclass":"view-detail-class"}
                              onClick={showDetails}
                            >
                              {props.data.entityId
                                ? "Download XLSX"                              
                                :""}
                            </Box>                            
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={11.7}>
                        {props.showViewButton && (
                          <Box className="message-second-class">
                            {props.convertDate && (
                              <Box className="time-class dat-time-cls">
                                {props.convertDate}
                              </Box>
                            )}
                            {props.convertTime && (
                              <Box className="time-class dat-time-cls">
                                {props.convertTime}
                              </Box>
                            )}
                          </Box>
                        )}
                      </Grid>
                    </Box>
                  </Box>{" "}
                </Grid>
              </>
            )}
          </Grid>
          <Grid item className="toast-cancel">
            {props.closeIcon && (
              <CancelIcon
                className="cancel-icon"
                onClick={handleClose}
              ></CancelIcon>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
