import { Box, Grid } from "@mui/material";
import { SubGroupFilterItem } from "pages/TargetV2/types/targetV2.types";
import React, { FC, useState, useEffect } from "react";
import "../pastInteraction/PastInteractionSubGroupContainer.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTargetV2Context } from "pages/TargetV2/context/targetV2.context";
import "../SubGroupFilterCustom.scss";
import OperatorLine from "../../components/operatorLine/OperatorLine";
import MCCPFilterRow from "../../filterRow/MCCPFilterRow";
import SubGroupFilterItemsContainer from "../../SubGroupFilterItemsContainer";
import arrow_up_blue from "../../../../../../../assets/icons/icon-arrow-up-blue-active.svg";
import { useTranslation } from "react-i18next";

type MCCPNewContainerProps = {
  parentFilterItem: SubGroupFilterItem;
  subGroupFilterItem: SubGroupFilterItem;
  index: number;
  level: number;
  snapchot: any;
  groupType: any;
};

const MCCPNewContainer: FC<MCCPNewContainerProps> = ({
  parentFilterItem,
  subGroupFilterItem,
  index,
  level,
  snapchot,
  groupType
}) => {
  const { t } = useTranslation();
  const { handleDeleteSubGroupFilterItem, isApplyingFilters } =
    useTargetV2Context();
  const [openAccordion, setOpenAccordion] = useState(true);
  const [showLabel, setShowLabel] = useState(true);
  const toggleAccordion = () => {
    setOpenAccordion(!openAccordion);
  };
  useEffect(() => {
    if (parentFilterItem && parentFilterItem.isMCCPGroup) {
      setShowLabel(false);
    } else {
      setShowLabel(true);
    }
  }, [parentFilterItem]);
  return (
    <Box
      marginRight="22px"
      marginTop="10px"
      marginLeft="12px"
      marginBottom="22px"
    >
      <Grid className="sub-group-custom-wrapper" position="relative">
        <Box
          display="flex"
          justifyContent={showLabel ? "space-between" : "flex-end"}
          marginTop="8px"
          alignItems="center"
        >
          {showLabel && (
            <Box display="flex" alignItems="center" gap="7px">
              <Box className="past-interaction-title-wrapper">
                <Box className="past-interaction-title">
                  {subGroupFilterItem.name}
                </Box>
              </Box>
              <Box className="mccp_fill_up_info">{t("fill_up_mccp")}</Box>
            </Box>
          )}
          <Box gap="8px" display="flex" alignItems="center">
            <img
              src={arrow_up_blue}
              alt="arrow up icon"
              style={{
                cursor: "pointer",
                rotate: openAccordion ? "0deg" : "180deg",
              }}
              onClick={toggleAccordion}
            />
            <CancelIcon
              onClick={() =>
                handleDeleteSubGroupFilterItem(
                  subGroupFilterItem.parentId,
                  index
                )
              }
              cursor="pointer"
              className="cancel-icon"
            />
          </Box>
        </Box>

        <Box
          display="flex"
          flexWrap="wrap"
          padding="16px"
          paddingTop="6px"
          flexDirection="column"
          // marginTop="22px"
        >
          {!openAccordion &&
            subGroupFilterItem.filterItemsChildren.length > 0 && (
              <Box
                key={subGroupFilterItem.filterItemsChildren[0].filterId}
                position="relative"
              >
                <MCCPFilterRow
                  index={0}
                  name={subGroupFilterItem.filterItemsChildren[0].name}
                  filters={subGroupFilterItem.filterItemsChildren[0].filters}
                  filtersSelected={
                    subGroupFilterItem.filterItemsChildren[0].filtersSelected
                  }
                  id={subGroupFilterItem.subGroupId}
                  level={level}
                  isMandatory={
                    subGroupFilterItem.filterItemsChildren[0].isMandatory
                  }
                  snapchot={snapchot}
                  isResponsive={
                    subGroupFilterItem.filterItemsChildren[0].isResponsive
                  }
                  parentItem={subGroupFilterItem}
                />

                {isApplyingFilters &&
                  subGroupFilterItem.filterItemsChildren[0]?.countHCPS !==
                    null && (
                    <Box
                      style={{
                        position: "absolute",
                        right: -158,
                        bottom: 26,
                      }}
                    >
                      <Box className="count-hcps-wrapper">
                        <Box className="count-hcps-number">
                          {subGroupFilterItem.filterItemsChildren[0].countHCPS}{" "}
                          HCPS
                        </Box>
                      </Box>
                    </Box>
                  )}
              </Box>
            )}
          {openAccordion &&
            subGroupFilterItem.filterItemsChildren.length > 0 &&
            subGroupFilterItem.filterItemsChildren.map((filterItem, i) => (
              <Box key={filterItem.filterId} position="relative">
                <MCCPFilterRow
                  index={i}
                  name={filterItem.name}
                  filters={filterItem.filters}
                  filtersSelected={filterItem.filtersSelected}
                  id={subGroupFilterItem.subGroupId}
                  level={level}
                  isMandatory={filterItem.isMandatory}
                  snapchot={snapchot}
                  isResponsive={filterItem.isResponsive}
                  parentItem={subGroupFilterItem}
                />
                {i < subGroupFilterItem.filterItemsChildren.length - 1 && (
                  <Box marginLeft="34px">
                    <OperatorLine
                      operator={subGroupFilterItem.operator}
                      width="84%"
                    />
                  </Box>
                )}
                {isApplyingFilters && filterItem?.countHCPS !== null && (
                  <Box
                    style={{
                      position: "absolute",
                      right: level <= 1 ? -158 : -212,
                      bottom:
                        i < subGroupFilterItem.filterItemsChildren.length - 1
                          ? 60
                          : 26,
                    }}
                  >
                    <Box className="count-hcps-wrapper">
                      <Box className="count-hcps-number">
                        {filterItem.countHCPS} HCPS
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
          {openAccordion &&
            subGroupFilterItem.subgGroupFilterItemsChildren.length > 0 && (
              <>
                <Box marginLeft="34px">
                  <OperatorLine
                    operator={subGroupFilterItem.operator}
                    width="84%"
                  />
                </Box>
                <div style={{ marginTop: "16px" }}>
                  {subGroupFilterItem.subgGroupFilterItemsChildren.map(
                    (subGroupFilterItemChildren, index) => (
                      <>
                        {index > 0 && (
                          <Box marginLeft="34px">
                            <OperatorLine
                              operator={subGroupFilterItem.operator}
                              width="84%"
                            />
                          </Box>
                        )}
                        <div key={index} style={{ position: "relative" }}>
                          <SubGroupFilterItemsContainer
                            subGroupFilterItem={subGroupFilterItemChildren}
                            level={level + 1}
                            index={index}
                            groupType={groupType}
                          />
                        </div>
                      </>
                    )
                  )}
                </div>
              </>
            )}
        </Box>
      </Grid>
    </Box>
  );
};

export default MCCPNewContainer;
