import { atom } from "recoil";
import { AtomObj } from "models/shared";

/**
 * State to manage data connection between two form elements
 * Eg: State depends on country
 */
const sharedFormDataAtom: AtomObj = {
  key: "form-element",
  default: null,
};
export const sharedFormElementState = atom(sharedFormDataAtom);

/**
 * State to manage level one filter for new targeting
 */
const profileFilterAtom: AtomObj = {
  key: "level-one-filter",
  default: {},
};
export const profileFilterState = atom(profileFilterAtom);

/**
 * State to manage level two filter for new targeting
 */
const demographicFilterAtom: AtomObj = {
  key: "level-two-filter",
  default: {},
};
export const demographicFilterState = atom(demographicFilterAtom);

/**
 * State to manage behaiour filter for new targeting
 */
const behaviourFilterAtom: AtomObj = {
  key: "behaviour-filter",
  default: {},
};
export const behaviourFilterState = atom(behaviourFilterAtom);

/**
 * State to manage past interaction filter for new targeting
 */
const pastInteractionFilterAtom: AtomObj = {
  key: "past-interaction-filter",
  default: {},
};
export const pastInteractionFilterState = atom(pastInteractionFilterAtom);

/**
 * State to manage level one filter config for filter search results
 */
const profileFilterConfigAtom: AtomObj = {
  key: "level-one-filter-config",
  default: null,
};
export const profileFilterConfigState = atom(profileFilterConfigAtom);
/**
 * State to manage level two filter config for filter search results
 */
const demographicFilterConfigAtom: AtomObj = {
  key: "level-two-filter-config",
  default: null,
};
export const demographicFilterConfigState = atom(demographicFilterConfigAtom);
/**
 * State to manage behaviour filter config for filter search results
 */
const behaviourFilterConfigAtom: AtomObj = {
  key: "behaviour-filter-config",
  default: null,
};
export const behaviourFilterConfigState = atom(behaviourFilterConfigAtom);

/**
 * State to manage past interaction  filter config for filter search results
 */
const pastInteractionFilterConfigAtom: AtomObj = {
  key: "past-interaction-filter-config",
  default: null,
};
export const pastInteractionFilterConfigState = atom(
  pastInteractionFilterConfigAtom
);

/**
 * State to manage simulator landing page state
 */
const simulatorAtom: AtomObj = {
  key: "simulator-config",
  default: {},
};
export const simulatorState = atom(simulatorAtom);
/**
 * State to manage notification
 */
const notificationAtom: AtomObj = {
  key: "notification-config",
  default: {},
};
export const notificationState = atom(notificationAtom);

/**
 * State to manage user details
 */
const userDetails: AtomObj = {
  key: "user-details",
  default: {},
};
export const userDetailsState = atom(userDetails);

/**
 * State to manage user country details
 */
const userCountryDetails: AtomObj = {
  key: "user-country-details",
  default: [],
};
export const userCountryDetailsState = atom(userCountryDetails);

/**
 * State to manage push notification response
 */
const firbaseResponse: AtomObj = {
  key: "firebase-response",
  default: {},
};
export const firbaseResponseState = atom(firbaseResponse);

/**
 * State to manage push notification response
 */
const messageQueueResponse: AtomObj = {
  key: "message-queue-response",
  default: {},
};
export const messageQueueResponseState = atom(messageQueueResponse);

/**
 * State to manage sfmc push from home page
 */
const journeyInfo: AtomObj = {
  key: "journey-info",
  default: {},
};
export const journeyInfoState = atom(journeyInfo);

/**
 * State to apps butto name
 */
const appsName: AtomObj = {
  key: "apps-name",
  default: {},
};
export const appsNameState = atom(appsName);

/**
 * State to manage surevy info filter config for filter search results
 */
const surveyInfo: AtomObj = {
  key: "survey-info",
  default: null,
};
export const surveyInfoState = atom(surveyInfo);

/**
 * State to manage surevy info filter config for filter search results
 */
const surveyDetails: AtomObj = {
  key: "survey-details",
  default: null,
};
export const surveyDetailsState = atom(surveyDetails);

/**
 * State to manage sfmc push from home page
 */
const pushStatusInfo: AtomObj = {
  key: "push-status-info",
  default: {},
};
export const pushStatusInfoState = atom(pushStatusInfo);

/**
 * State to manage sfmc push from home page
 */
const pushMsgCount: AtomObj = {
  key: "push-msg-count",
  default: null,
};
export const pushMsgCountState = atom(pushMsgCount);

// State manaegement for custom toggle of new home page
export const alignmentState = atom({
  key: 'alignmentState', // Unique identifier
  default: 'apps', // Initial value
});

/**
 * apps infor for home page
 */
const appsInfo: AtomObj = {
  key: "appsInfo-info",
  default: {},
};
export const appsInfoState = atom(appsInfo);

/**
 * State to manage user custom toast details
 */
const customToastInfo: AtomObj = {
  key: "custom-toast-info",
  default: [],
};
export const customToastInfoState = atom(customToastInfo);

/**
 * State to manage selected apps info
 */
const selectedAppsInfo: AtomObj = {
  key: "sele-apps-info",
  default: {},
};
export const selectedAppsInfoState = atom(selectedAppsInfo);
/**
 * State to manage preview of custom filter
 */
const userUploadedCSVAtom: AtomObj = {
  key: "user-updated-csv",
  default: {},
};
export const userUploadedCSVState = atom(userUploadedCSVAtom);
/**
 * State to store the user operation
 */
const userOperationAtom: AtomObj = {
  key: "user-operation",
  default: {},
};
export const userOperationState = atom(userOperationAtom);
/**
 * State to store the BayerCRMID available
 */
const CRMIDPresentAtom: AtomObj = {
  key: "id-present",
  default: {},
};
export const CRMIDPresentState = atom(CRMIDPresentAtom);