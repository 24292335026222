import { Box } from "@mui/material";
import React from "react";
import empty_box from "../../../../assets/emptyBox.svg";
import { useTranslation } from "react-i18next";

const EmptyBox = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      alignItems="center"
      marginTop="44px"
      flexDirection="column"
      gap="16px"
      position="absolute"
      top="25%"
      left="24%"
    >
      <img src={empty_box} alt="empty-box" />
      <Box display="flex" flexDirection="column" alignItems="center" gap="14px">
        <Box className="no-filter-title">{t("no_filter_add_yet")}</Box>
        <Box className="no-filter-desc">{t("start_target_list")}</Box>
      </Box>
    </Box>
  );
};

export default EmptyBox;
